import type { ISimpleRegion } from '~/types'

export default class RegionsApi {
  private readonly axios = axiosClient
  name: string

  constructor(name: string) {
    this.name = name
  }

  get(): Promise<ISimpleRegion> {
    return this.axios.$get('/region_name/', { params: { translit: this.name } })
  }
}
